<template>
  <div :class="wrapperClass">
    <div class="total-with-sort">
      <span class="total">
        <span v-text="state.search.total_items || 0"></span>
        &nbsp;người theo dõi thương hiệu
      </span>
      <a class="btn-sort" @click="sortFollow">
        <span>A - Z</span>
        <i
          class="dsi dsi-s16"
          :class="[params.sort_order === 'desc' ? 'dsi-arrow-down' : 'dsi-arrow-up']"
        ></i>
      </a>
    </div>

    <ul class="interaction-list">
      <li v-for="(user, i) in state.followers" :key="i">
        <router-link
          class="interaction-item_wrapper"
          :to="{ name: 'profile', query: { profile_id: user.user_id } }"
        >
          <div class="interaction-item_img">
            <router-link
              class="img"
              :to="{ name: 'profile', query: { profile_id: user.user_id } }"
            >
              <img
                width="40"
                height="40"
                v-lazy="{ src: _userAvatar(user.avatar), error: _userRandomAvatar() }"
              />
            </router-link>
          </div>

          <div class="interaction-item_info">
            <a>
              <h5 class="interaction-item_title" v-text="user.firstname"></h5>
            </a>
            <div class="interaction-item_social">
              <strong>0</strong>&nbsp;người theo dõi
            </div>
          </div>

          <div class="interaction-item_follow">
            <svg
              width="10"
              height="16"
              viewBox="0 0 10 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.12876 1.70549L7.42801 7.95021L1.04266 14.2803C0.90345 14.4183 0.833323 14.5915 0.833323 14.7859C0.833323 14.9803 0.903451 15.1536 1.04265 15.2916C1.10955 15.3579 1.18663 15.4109 1.27392 15.447C1.36128 15.483 1.45372 15.5 1.5487 15.5C1.63842 15.5 1.72616 15.4814 1.81044 15.4493C1.90298 15.414 1.98486 15.3608 2.05474 15.2916L8.95732 8.44873C9.09493 8.31232 9.16666 8.14215 9.16666 7.95021C9.16666 7.75827 9.09493 7.5881 8.95732 7.45168L2.15519 0.70844C2.01588 0.570328 1.84008 0.5 1.64198 0.5C1.44387 0.5 1.26808 0.570328 1.12876 0.70844C0.991157 0.844852 0.919425 1.01502 0.919425 1.20697C0.919425 1.39891 0.991157 1.56908 1.12876 1.70549Z"
                fill="#808080"
              ></path>
            </svg>
          </div>
        </router-link>
      </li>
    </ul>

    <Pagination
      :wrapperClass="'pagination_details'"
      v-if="state.search.totalPage > 1"
      v-model="params.page"
      @update:modelValue="getFollowers"
      :pages="state.search.totalPage"
    />
  </div>
</template>

<script>
import FollowableService from "@/services/followable";
import Pagination from "@/components/pagination/Pagination";

import { onMounted, reactive, inject } from "@vue/runtime-core";
import { useRoute } from "vue-router";

export default {
  components: {
    Pagination,
  },
  props: {
    wrapperClass: {
      type: String,
      default: "",
    },
    getType: {
      type: String,
      default: "follower",
    },
    type: {
      type: String,
      default: "brand",
    },
  },

  setup(props) {
    const brand = inject("brand");
    const route = useRoute();
    const state = reactive({
      followers: [],
      search: {},
    });

    const params = reactive({
      page: route.query.page ? route.query.page : 1,
      getType: props.getType,
      type: props.type,
      sort_by: "name",
      sort_order: route.query.sort_order ? route.query.sort_order : "desc",
    });

    onMounted(() => {
      getFollowers();
    });

    const getFollowers = async () => {
      const response = await FollowableService.fetchFollowers(
        brand.value.company_id,
        params
      );

      if (response && response.data) {
        state.followers = response.data.followers;
        state.search = response.data.search;
        state.search.totalPage = Math.ceil(
          response.data.search.total_items / response.data.search.items_per_page
        );

        const container = document.querySelector(".brand-shop-detail-page .modal-body");
        container.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    };
    const sortFollow = async () => {
      if (params.sort_order == "desc") params.sort_order = "asc";
      else params.sort_order = "desc";
      params.page = 1;

      getFollowers();
    };

    return {
      state,
      params,
      sortFollow,
      getFollowers,
    };
  },
};
</script>
