<template>
  <LoadingComponent v-if="dosiinEmptyObject(state.discussionData.summary)" />
  <div v-else>
    <div class="review-overview p_review_box">
      <div class="review-overview-inner">
        <div class="box-review r_details-left">
          <div class="review-overview-total p_review-total">
            <div class="rating-number rating-txtt">
              {{ ratingFormat(state.discussionData.summary.avg_rating)
              }}<span>trên 5</span>
            </div>
          </div>
          <div class="rating-star">
            <star-rating
              :rating="
                state.discussionData.summary.avg_rating
                  ? parseInt(state.discussionData.summary.avg_rating)
                  : 0
              "
              :star-size="16"
              :read-only="true"
              :show-rating="false"
              text-class="product-tem_social-text"
              active-color="#FFCC07"
              :star-points="[
                23,
                2,
                14,
                17,
                0,
                19,
                10,
                34,
                7,
                50,
                23,
                43,
                38,
                50,
                36,
                34,
                46,
                19,
                31,
                17,
              ]"
            />
            <p class="rating-review rating-cmt">
              {{ state.discussionData.summary.total_post }}<span>đánh giá</span>
            </p>
          </div>
        </div>
        <div class="r_details-middle dosiin_mt-2">
          <ul class="rating-detail" v-if="discussionCount">
            <template v-for="(rating, index) in discussionCount">
              <li class="rating-detail-item" v-if="rating" :key="index">
                <span class="order-number">{{ rating.rating_value }}</span>
                <i class="dsi dsi-fill-star"></i>
                <div class="percentage-bar">
                  <span
                    class="percentage-bar-active"
                    :style="`width:${rating.percent}%`"
                  ></span>
                </div>
                {{ rating.percent }}%
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>

    <section class="filter-inner">
      <h3 class="tab-bar-filter-title">Toàn bộ đánh giá</h3>
      <div class="tab-bar-filter_box">
        <div class="tab-bar-filter-item p_bg-filter">
          <span class="text-item dosiin_mr-1">Bộ lọc</span>
          <div class="icon_filter"><i class="dsi dsi-filter-setting"></i></div>
        </div>
        <div class="tab-bar-filter-item-icon">
          <svg
            width="1"
            height="32"
            viewBox="0 0 1 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="1" height="32" fill="#E6E6E6"></rect>
          </svg>
        </div>
        <div
          class="tab-bar-filter-item p_filter-item dosiin_ml-2"
          @click="discussionPaginate.rating_value = null"
          :class="{ active: !discussionPaginate.rating_value }"
        >
          <span class="text-item">Toàn bộ</span>
        </div>
        <div
          class="tab-bar-filter-item p_filter-item"
          @click="discussionPaginate.rating_value = 5"
          :class="{ active: discussionPaginate.rating_value === 5 }"
        >
          <i class="dsi dsi-s16 dsi-act-fill-star"></i
          ><span class="text-item dosiin_ml-2">5 </span>
        </div>
        <div
          class="tab-bar-filter-item p_filter-item"
          @click="discussionPaginate.rating_value = 4"
          :class="{ active: discussionPaginate.rating_value === 4 }"
        >
          <i class="dsi dsi-s16 dsi-fill-star"></i
          ><span class="text-item dosiin_ml-2">4</span>
        </div>
        <div
          class="tab-bar-filter-item p_filter-item"
          @click="discussionPaginate.rating_value = 3"
          :class="{ active: discussionPaginate.rating_value === 3 }"
        >
          <i class="dsi dsi-s16 dsi-fill-star"></i
          ><span class="text-item dosiin_ml-2">3</span>
        </div>
        <div
          class="tab-bar-filter-item p_filter-item"
          @click="discussionPaginate.rating_value = 2"
          :class="{ active: discussionPaginate.rating_value === 2 }"
        >
          <i class="dsi dsi-s16 dsi-fill-star"></i
          ><span class="text-item dosiin_ml-2">2</span>
        </div>
        <div
          class="tab-bar-filter-item p_filter-item"
          @click="discussionPaginate.rating_value = 1"
          :class="{ active: discussionPaginate.rating_value === 1 }"
        >
          <i class="dsi dsi-s16 dsi-fill-star"></i
          ><span class="text-item dosiin_ml-2">1 </span>
        </div>
      </div>
    </section>

    <section class="customer-review-section dosiin_pd-16" ref="discussionWrapper">
      <DiscussionsList :discussions="state.discussionData.posts" />
    </section>

    <Pagination
      :wrapperClass="'pagination_details'"
      v-if="state.discussionParams.totalPage > 1"
      v-model="discussionPaginate.page"
      @update:modelValue="getDiscussionBrand"
      :pages="state.discussionParams.totalPage"
    />
  </div>
</template>

<script>
import { inject, ref, watch } from "vue";
import StarRating from "vue-star-rating";
import DiscussionsList from "@/components/common/DiscussionsList";
import Pagination from "@/components/pagination/Pagination";

export default {
  components: {
    Pagination,
    StarRating,
    DiscussionsList,
  },
  setup() {
    const state = inject("state");
    const getDiscussionBrand = inject("getDiscussionBrand");
    const discussionPaginate = inject("discussionPaginate");
    const sortDiscussion = inject("sortDiscussion");
    const discussionWrapper = inject("discussionWrapper");
    let discussionCount = ref([]);

    getDiscussionBrand();

    watch(
      () => state.discussionData,
      () => {
        discussionCount.value = state.discussionData.summary.count_rating;
        let total_items = parseInt(state.discussionData.summary.total_post);

        for (let i = 5; i > 0; i--) {
          let percent = 0;
          if (discussionCount.value[i] && discussionCount.value[i].total_rating > 0) {
            percent = Math.ceil(
              (parseInt(discussionCount.value[i].total_rating) / total_items) * 100
            );
          }
          if (!discussionCount.value[i])
            discussionCount.value[i] = {
              total_rating: 0,
              rating_value: i,
              percent,
            };
          else discussionCount.value[i].percent = percent;
        }
      }
    );

    return {
      state,
      discussionWrapper,
      discussionPaginate,
      discussionCount,
      sortDiscussion,
      getDiscussionBrand,
    };
  },
};
</script>
