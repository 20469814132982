<template>
  <ul
    class="review-item-preview_right dosiin_gallery"
    v-if="post && post.images"
    :id="`post_${post.post_id}`"
  >
    <li v-for="(image, index) in rebuildImageDiscussion(post.images)" :key="index">
      <a
        :href="image.largeURL"
        :data-pswp-width="670"
        :data-pswp-height="670"
        target="_blank"
        rel="noreferrer"
      >
        <img class="uploaded-img" :src="image.thumbnailURL" />
      </a>
    </li>
  </ul>
</template>

<script>
import PhotoSwipeLightbox from "@/plugins/photoswipe/photoswipe-lightbox.esm.js";
import "@/plugins/photoswipe/photoswipe.css";
import PhotoSwipe from "@/plugins/photoswipe/photoswipe.esm.js";
import { onMounted, onUpdated } from "@vue/runtime-core";
import { _commentThumb } from "@/utlis/thumbnail";
export default {
  props: {
    post: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const images = [];

    onMounted(() => {
      const lightbox = new PhotoSwipeLightbox({
        gallery: "#post_" + props.post.post_id,
        children: "a",
        pswpModule: () => PhotoSwipe,
      });

      lightbox.init();
    });

    const rebuildImageDiscussion = (images) => {
      let imgs = images.split(",");
      let imgObjects = [];
      for (let i = 0; i < imgs.length; i++) {
        imgObjects.push({
          largeURL: _commentThumb(imgs[i], false),
          thumbnailURL: _commentThumb(imgs[i], true),
          title: "Hình ảnh đánh giá từ khách hàng (" + imgs.length + ")",
        });
      }
      return imgObjects;
    };

    return {
      rebuildImageDiscussion,
    };
  },
};
</script>
