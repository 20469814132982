<template>
  <div class="dosiin_text-center" v-if="discussions.length === 0">
    <img :src="_emptyReview" />
    <p class="">Hãy là người đầu tiên đánh giá sản phẩm này</p>
  </div>
  <ul class="dosiin_review-list detailed-review-list" v-else>
    <li
      class="review-item review-item-product"
      v-for="(discussion, index) in discussions"
      :key="index"
    >
      <div class="review-item-info">
        <router-link :to="{ name: 'profile', query: { profile_id: discussion.user_id } }">
          <img
            width="40"
            height="40"
            class="user-avatar"
            v-lazy="{
              src: _userAvatar(discussion.user_avatar),
              loading: _userDefaultAvatar,
              error: _userDefaultAvatar,
            }"
          />
        </router-link>

        <div class="rating-info">
          <h3 class="user-name truncate" v-text="discussion.name"></h3>
          <div class="rating-with-time">
            <span
              class="rating-time-product dosiin_mr-2"
              v-text="_momentFromNow(discussion.timestamp)"
            ></span>
            <star-rating
              :rating="parseInt(discussion.rating_value)"
              :star-size="12"
              :read-only="true"
              :show-rating="false"
              text-class="product-tem_social-text"
              active-color="#FFCC07"
              :star-points="[
                23,
                2,
                14,
                17,
                0,
                19,
                10,
                34,
                7,
                50,
                23,
                43,
                38,
                50,
                36,
                34,
                46,
                19,
                31,
                17,
              ]"
            />
          </div>

          <div class="review-item-preview">
            <div class="review-item-preview_left">
              <div
                class="list-item product-list-item dosiin_mt-2"
                v-if="discussion.product"
              >
                <router-link :to="_productUrl(discussion.product)" class="list-item_img"
                  ><img
                    class="product-sm-item_img"
                    :alt="discussion.product.product"
                    :title="discussion.product.product"
                    width="76"
                    height="76"
                    v-lazy="
                      _productThumbnailSticker(
                        discussion.product.main_pair,
                        discussion.product.sticker.main_pair
                      )
                    "
                /></router-link>
                <dl class="list-item_summary">
                  <dt>
                    <a class="list-item_brand">{{ discussion.product.company_name }}</a>
                  </dt>
                  <dd class="list-item_name" v-text="discussion.product.product"></dd>
                </dl>
              </div>
              <ul class="review-product-tab-list review_p-tab-list">
                <li class="review-product-tab dosiin_mr-2" v-if="discussion.reviewText">
                  {{ ReviewText[discussion.reviewText] }}
                </li>
                <li class="review-product-tab dosiin_mr-2" v-if="discussion.cm">
                  {{ discussion.cm }}CM
                </li>
                <li class="review-product-tab" v-if="discussion.kg">
                  {{ discussion.kg }}KG
                </li>
              </ul>

              <div class="review-item-description">
                <span v-text="discussion.message"></span>
              </div>

              <DiscussionGallery v-if="discussion.images" :post="discussion" />
            </div>
          </div>

          <div class="review-item-preview_right"></div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import StarRating from "vue-star-rating";
import { ReviewText } from "@/utlis/global";
import { _commentThumb } from "@/utlis/thumbnail";
import DiscussionGallery from "@/components/images/DiscussionGallery";
export default {
  components: {
    StarRating,
    DiscussionGallery,
  },
  props: {
    discussions: {
      type: Array,
      default: [],
    },
  },
  setup() {
    return {
      ReviewText,
    };
  },
};
</script>
