<template>
  <div>
    <div class="brand-shop-events-detail">
      <div class="box-wrapper-pc">
        <section class="mag-new-image-section">
          <div class="section-title-wrapper">
            <h2 class="section-title">Hình ảnh từ người dùng</h2>
            <div class="dosiin_slider-controls">
              <div
                class="dosiin_pagination-brand swipable-slider_pagination"
                id="indicators"
              ></div>
            </div>
          </div>
          <div class="dosiin_position-relative">
            <div class="slider-brand-shop overflow">
              <Swiper
                :slidesPerView="6"
                :modules="modules"
                :spaceBetween="8"
                :navigation="{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                }"
                :pagination="pagination"
                :class="{ 'dosiin_position-unset': layout != 4 }"
              >
                <SwiperSlide v-for="(post, i) in state.imagesVnfd" :key="i">
                  <div class="new-image-wrapper">
                    <router-link
                      class="new-image_banner"
                      :to="{ name: 'profile', query: { profile_id: post.user_id } }"
                      ><img
                        class="new-image_img"
                        v-lazy="_postImage(post.main_pair)"
                        width="165"
                        height="220"
                      />
                      <div class="user-icon outer-border">
                        <router-link
                          class="user-icon_inner"
                          :to="{ name: 'profile', query: { profile_id: post.user_id } }"
                          ><img
                            class="new-image_ava"
                            v-lazy="{
                              src: _userAvatar(post.avatar),
                              loading: _userDefaultAvatar,
                              error: _userDefaultAvatar,
                            }"
                            width="36"
                            height="36"
                        /></router-link>
                      </div>
                    </router-link>
                    <dl class="new-image_info">
                      <dd class="user-name">{{ post.user_name }}</dd>
                    </dl>
                  </div>
                </SwiperSlide>
                <div
                  class="swiper-button-next dosiin_swiper-button dosiin_position-button-next"
                ></div>
                <div
                  class="swiper-button-prev dosiin_swiper-button dosiin_position-button-prev"
                ></div>
              </Swiper>
            </div>
          </div>
        </section>
        <div class="box-grid-pc dosiin_mt-24">
          <div class="box-grid-pc_left">
            <PostsLoader
              ref="postLoader"
              :params="{
                sort_by: 'id',
                brand_id: brand.company_id,
                timestamp: new Date().getTime(),
              }"
              @total-post="(total) => (postCount = total)"
            />
          </div>
          <div class="box-grid-pc_right sticky-content">
            <GroupTags />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
import { Navigation, Pagination } from "swiper";
import PostsLoader from "@/components/post/PostLoader.vue";
import GroupTags from "@/components/hashtag/GroupTags.vue";

export default {
  components: {
    PostsLoader,
    GroupTags,
  },
  setup() {
    const brand = inject("brand");
    const state = inject("state");
    const getImagesFromVnfd = inject("getImagesFromVnfd");

    getImagesFromVnfd();

    return {
      brand,
      state,
      modules: [Navigation, Pagination],
      pagination: {
        el: ".dosiin_pagination-brand-img",
        clickable: true,
        renderBullet: (index, className) =>
          `<div data="${index}" class="swipable-slider_indicator ${className}"></div>`,
      },
    };
  },
};
</script>
