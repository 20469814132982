<template>
  <div class="page-section dosiin-shop-brand-store">
    <div class="brand-shop-store">
      <div class="brand-shop-store-left">
        <div class="category_aside-inner-brach">
          <div class="aside_header-box">
            <h4>Danh mục sản phẩm</h4>
            <button
              class="d-flex align-items-center"
              @click="cids = []"
              v-if="cids.length > 0"
            >
              <span class="dosiin_mr-2 reset">Đặt lại </span>
            </button>
          </div>
          <div class="line"></div>
          <div class="aside_bar-inner dosiin_pd-016">
            <ul class="aside_bar-list-brand">
              <li
                class="aside_bar-item"
                v-for="(category, index) in state.categories"
                :key="index"
              >
                <div class="aside_bar-item-box category">
                  <a class="dosiin_d-flex categories">
                    <div class="input-group-text-radio">
                      <input
                        :id="index"
                        type="radio"
                        v-model="cids"
                        :value="category.category_id"
                        :checked="cids.includes(category.category_id)"
                      />
                      <label :for="index" >
                        <span v-text="category.category"></span>
                      </label>
                    </div>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="brand-shop-store-right dosiin_w-100">
        <div class="section-title-wrapper">
          <div class="title-result">
            <h2 class="section-title">Toàn bộ sản phẩm</h2>
            <div class="result-product-sale">({{ state.totalProduct }} kết quả)</div>
          </div>
        </div>

        <div class="tab-bar-brand-shop">
          <FitersBarPc
            ref="filterRef"
            :filterCategory="false"
            :filterBrand="false"
            :maxPrice="maxPrice"
            :totalProducts="state.totalProduct"
          />
        </div>

        <ProductLoader
          :query="currentParams"
          gridClass="grid-20"
          :changeGrid="false"
          @total-products="(total) => (state.totalProduct = total)"
          @maxPrice="(max) => (maxPrice = max)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProductLoader from "@/components/product/ProductLoader";
import { ref, inject, watch } from "vue";
import FitersBarPc from "@/components/filters/FiltersBarPc";
import { useRoute } from "vue-router";

export default {
  components: {
    ProductLoader,
    FitersBarPc,
  },
  setup() {
    const route = useRoute();
    const getCategoriesBrand = inject("getCategoriesBrand");
    const currentParams = inject("params");
    const brand = inject("brand");
    const state = inject("state");
    const cids = ref(route.query.cid ? route.query.cid.split(",") : []);
    const maxPrice = ref(0);
    const totalProducts = ref(0);
    const filterRef = ref(null);

    if (route.query.cid) {
      currentParams.value.cid = route.query.cid;
    }

    getCategoriesBrand();
    watch(
      () => cids.value,
      () => {
        let c = cids.value;
        let c2 = [c];
        filterRef.value.mainFilters.getCategories(c2);
      }
    );

    return {
      state,
      brand,
      currentParams,
      cids,
      maxPrice,
      totalProducts,
      filterRef,
    };
  },
};
</script>
