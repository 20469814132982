<template>
  <div v-if="brand.company_id">
    <div class="mag-new-image-section page-section" :class="{ overflow: layout == 4 }">
      <div class="section-title-wrapper">
        <h2 class="section-title">Hình ảnh</h2>
        <div class="dosiin_slider-controls">
          <div class="dosiin_pagination-brand-img swipable-slider_pagination"></div>
        </div>
      </div>
      <div class="sliderBrandShop dosiin_position-unset">
        <Swiper
          :modules="modules"
          :slidesPerView="2.1"
          :spaceBetween="8"
          :slidesPerGroup="2"
          :slideToClickedSlide="false"
          :pagination="pagination"
          :class="{ 'overflow-unset': layout == 4 }"
        >
          <SwiperSlide v-for="(post, i) in state.imagesVnfd" :key="i">
            <router-link
              :to="{ name: 'profile', query: { profile_id: post.user_id } }"
              class="new-image-wrapper"
            >
              <div class="">
                <div class="new-image_banner">
                  <img
                    class="new-image_img"
                    v-lazy="_postImage(post.main_pair)"
                    width="165"
                    height="220"
                  />
                  <router-link
                    :to="{ name: 'profile', query: { profile_id: post.user_id } }"
                    class="user-icon outer-border"
                  >
                    <div class="user-icon_inner">
                      <img
                        class="new-image_ava"
                        v-lazy="{
                          src: _userAvatar(post.avatar),
                          loading: _userDefaultAvatar,
                          error: _userDefaultAvatar,
                        }"
                        width="36"
                        height="36"
                      />
                    </div>
                  </router-link>
                </div>
                <div class="new-image_info">
                  <dd class="user-name">{{ post.user_name }}</dd>
                </div>
              </div>
            </router-link>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
    <div class="accordion-posts dosiin_mt-16">
      <div class="section-title-wrapper">
        <h2 class="section-title">Bài viết</h2>
      </div>
    </div>
    <PostsLoader
      ref="postLoader"
      :params="{
        sort_by: 'id',
        brand_id: brand.company_id,
        timestamp: new Date().getTime(),
      }"
      @total-post="(total) => (postCount = total)"
    />
  </div>
</template>

<script>
import PostsLoader from "@/components/post/PostLoader.vue";
import { Navigation, Pagination } from "swiper";
import { inject } from "vue";

export default {
  components: {
    PostsLoader,
  },
  setup() {
    const brand = inject("brand");
    const state = inject("state");
    const getImagesFromVnfd = inject("getImagesFromVnfd");

    getImagesFromVnfd();
    return {
      state,
      brand,
      modules: [Navigation, Pagination],
      pagination: {
        el: ".dosiin_pagination-brand-img",
        clickable: true,
        renderBullet: (index, className) =>
          `<div data="${index}" class="swipable-slider_indicator ${className}"></div>`,
      },
    };
  },
};
</script>
