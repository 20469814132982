<template>
  <BrandMB v-if="layout == 4" />
  <BrandPC v-else />
</template>

<script>
import BrandMB from "@/pages/mb/brand/Index";
import BrandPC from "@/pages/pc/brand/Index";
import BrandService from "@/services/brand";
import brandService from "@/services/brand";

import { provide, reactive, ref, watch } from "vue";
import { useRoute } from "vue-router";

export default {
  components: {
    BrandMB,
    BrandPC,
  },

  setup() {
    const brand = ref(null);
    const route = useRoute();
    const state = reactive({
      brandId: route.params.bid,
      discussion: {},
      categories: [],
      totalProduct: 0,
      imagesVnfd: [],
      showModalInfo: false,
      discussionData: {},
      discussionParams: { totalPage: 1, page: 1 },
    });

    const discussionPaginate = reactive({
      getDiscussion: true,
      page: 1,
    });
    const params = ref({
      page: 1,
      type_category: route.query.type_category ? route.query.type_category : "",
      sort_by: route.query.sort_by ? route.query.sort_by : "random", //popularity , percent_discount
      sort_order: route.query.sort_order ? route.query.sort_order : "desc",
      cid: route.query.cid ? route.query.cid : [],
      t: route.query.t ? route.query.t : null,
    });
    const discussionWrapper = ref(null);
    getBrand();

    async function getBrand() {
      const response = await BrandService.fetchBrand(route.params.bid);
      if (response.data) {
        brand.value = response.data;
      }
    }

    const getCategoriesBrand = async () => {
      const response = await brandService.fetchBrand(route.params.bid, {
        getCategories: true,
        getParent: true,
      });

      if (response && response.data) {
        state.categories = response.data;
      }
    };

    const getImagesFromVnfd = async () => {
      const response = await brandService.fetchBrand(route.params.bid, {
        getImageVnfd: true,
      });

      if (response && response.data) {
        state.imagesVnfd = response.data;
      }
    };

    const getDiscussionBrand = async () => {
      const response = await brandService.fetchBrand(route.params.bid, {
        ...discussionPaginate,
      });

      if (response && response.data) {
        state.discussionData = response.data;
        // state.discussionParams = response.data.params;
        state.discussionParams.totalPage = Math.ceil(
          response.data.params.total_items / response.data.params.items_per_page
        );
        state.discussionParams.page = response.data.params.page;
      }
    };

    watch(
      () => discussionPaginate.rating_value,
      () => {
        getDiscussionBrand();
      }
    );

    watch(
      () => discussionPaginate.page,
      () => {
        window.scrollTo(0, discussionWrapper.value.offsetTop - 125);
      }
    );

    const sortDiscussion = () => {
      if (!discussionPaginate.sort_order) discussionPaginate.sort_order = "desc";
      else if (discussionPaginate.sort_order === "desc")
        discussionPaginate.sort_order = "asc";
      else if (discussionPaginate.sort_order === "asc")
        discussionPaginate.sort_order = "desc";

      if (!discussionPaginate.sort_by) {
        discussionPaginate.sort_by = "timestamp";
      }

      getDiscussionBrand();
    };

    provide("brand", brand);
    provide("state", state);
    provide("params", params);
    provide("getImagesFromVnfd", getImagesFromVnfd);
    // methods
    provide("getCategoriesBrand", getCategoriesBrand);
    provide("getDiscussionBrand", getDiscussionBrand);
    provide("discussionPaginate", discussionPaginate);
    provide("sortDiscussion", sortDiscussion);

    provide("discussionWrapper", discussionWrapper);
  },
};
</script>
