<template>
  <div>
    <div
      class="page-section brand-shop-page-section new-promotion-brand dosiin_mt-12 overflow"
      v-if="state.promotions.length"
    >
      <div class="header-section">
        <h3 class="header-section-left-side section-title">Khuyến mãi mới</h3>
      </div>

      <div class="promo-list new-promotion-swiper">
        <PromotionsList :promotions="state.promotions" />
      </div>
    </div>

    <div class="page-section">
      <div class="section-title-wrapper">
        <h2 class="section-title">Sản phẩm bán chạy</h2>
      </div>
      <div class="dosiin_product-best-selling swiper-filter-container">
        <ul class="bestsellers-list">
          <template v-for="(product, index) in state.productsBestSelling" :key="index">
            <li class="list-item bestsellers-list-item" v-if="index < 5">
              <ProductHorizon :product="product" :rating="index + 1" />
            </li>
          </template>
        </ul>
      </div>
    </div>

    <div
      class="page-section brand-shop-page-section overflow category-brand"
      v-if="state.categories.length"
    >
      <div class="header-section">
        <h3 class="header-section-left-side section-title">Danh mục sản phẩm</h3>
      </div>

      <div class="category-brand-list category-brand-swiper">
        <Swiper
          :slidesPerView="3.5"
          :spaceBetween="9"
          :class="layout != 4 ? '' : 'overflow-unset'"
        >
          <SwiperSlide v-for="(category, index) in state.categories" :key="index">
            <router-link
              class="dosiin_all-categories all-categories center"
              :to="'/' + brand.seo_name + `?t=store&cid=${category.category_id}`"
            >
              <img
                class="all-categories_icon"
                v-lazy="_productThumbnailSticker(category.main_pair)"
                :alt="category.category"
                width="76"
                height="76"
              />
              <span
                class="all-categories_name truncate"
                v-text="category.category"
              ></span>
              <div class="all-categories_numbers truncate">
                <b>{{ numberFormat(category.products_count) }}</b> sản phẩm
              </div>
            </router-link>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>

    <div class="page-section">
      <div class="section-title-wrapper">
        <h2 class="section-title section-title-product">Gợi ý cho bạn</h2>
      </div>
      <ProductLoader
        :query="{ sort_by: 'random' }"
        gridClass="grid-33"
        :changeGrid="true"
      />
    </div>
  </div>
</template>

<script>
import PromotionsList from "@/components/common/PromotionsList";

import PromotionService from "@/services/promotion";
import ProductService from "@/services/product";
import BrandService from "@/services/brand";
import ProductLoader from "@/components/product/ProductLoader";
import ProductsSliderHorizon from "@/components/sliders/ProductsHorizon";
import ProductHorizon from "@/components/product/ProductItemHorizon";

import { reactive, inject } from "vue";
export default {
  components: {
    ProductLoader,
    PromotionsList,
    ProductHorizon,
    ProductsSliderHorizon,
  },

  setup() {
    const brand = inject("brand");
    const state = reactive({
      promotions: [],
      productsBestSelling: [],
      categories: [],
      productsSimilar: [],
    });

    getPromotions();
    getProductsBestSeller();
    getCategoriesBrand();
    async function getPromotions() {
      const response = await PromotionService.fetchPromotions({
        company_ids: brand.value.company_id,
        "expand[convert]": true,
        "expand[make_up]": true,
      });

      if (response && response.data) {
        state.promotions = response.data.promotions;
      }
    }

    async function getProductsBestSeller() {
      const response = await ProductService.fetchProducts({
        company_ids: brand.value.company_id,
        sort_by: "sold",
      });

      if (response && response.data) {
        state.productsBestSelling = response.data.products;
      }
    }

    async function getCategoriesBrand() {
      const response = await BrandService.fetchBrand(brand.value.company_id, {
        getCategories: true,
      });

      if (response && response.data) {
        state.categories = response.data;
      }
    }

    return {
      state,
      brand,
    };
  },
};
</script>
