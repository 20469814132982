<template>
  <div>
    <section class="page-section" v-if="state.promotions.length">
      <div class="section-title-wrapper">
        <h2 class="section-title">Khuyến mãi mới</h2>
      </div>
      <div class="brand_coupons_inner">
        <PromotionsList :promotions="state.promotions" />
      </div>
    </section>

    <ProductsSliderHorizon
      wrapperClass="page-section"
      title="Sản phẩm bán chạy"
      gridClass="grid-20"
      :products="state.productsBestSeller"
      :options="{
        slidesPerView: 5,
        slidesPerGroup: 5,
        spaceBetween: 16,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      }"
    />

    <div class="page-section" v-if="state.categories.length">
      <div class="home_section-inner">
        <div class="section-title-wrapper">
          <h2 class="section-title">Danh mục sản phẩm</h2>
        </div>
        <ul class="all-categories_content all-categories_content-list">
          <li
            class="dosiin_all-categories"
            v-for="(category, index) in state.categories"
            :key="index"
          >
            <router-link
              :to="'/' + brand.seo_name + `?t=store&cid=${category.category_id}`"
              class="all-categories center all-category_item"
            >
              <div class="category_box-body">
                <img
                  class="all-categories_icon"
                  v-lazy="_productThumbnailSticker(category.main_pair)"
                  :alt="category.category"
                  width="40"
                  height="40"
                  style="filter: unset"
                />
                <!-- <div class="all-categories_icon">
                  <i
                    :class="[
                      'icon-categories dsi dsi-s40',
                      getCategoryIcon(category.category_id),
                    ]"
                  ></i>
                </div> -->
                <div class="category_box-txt">
                  <div class="all-categories_name" v-text="category.category"></div>
                  <div class="all-categories_numbers">
                    <b>{{ numberFormat(category.products_count) }}</b> sản phẩm
                  </div>
                </div>
              </div>
              <i class="dsi dsi-chevron-right"></i>
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    <section class="page-section">
      <div class="section-title-wrapper">
        <h2 class="section-title">Gợi ý cho bạn</h2>
      </div>

      <ProductLoader
        :query="{
          company_ids: brand.company_id,
        }"
        gridClass="grid-20"
        :changeGrid="false"
        :hidePagination="true"
      />
    </section>
  </div>
</template>

<script>
import ProductsSliderHorizon from "@/components/sliders/ProductsHorizon";
import PromotionsList from "@/components/common/PromotionsList";
import { reactive, inject } from "vue";
import PromotionService from "@/services/promotion";
import ProductService from "@/services/product";
import BrandService from "@/services/brand";
import ProductLoader from "@/components/product/ProductLoader";
export default {
  components: { PromotionsList, ProductsSliderHorizon, ProductLoader },
  setup() {
    const brand = inject("brand");
    const state = reactive({
      promotions: [],
      productsBestSeller: [],
      categories: [],
      productsSimilar: [],
    });

    getPromotions();
    getProductsBestSeller();
    getCategoriesBrand();

    async function getPromotions() {
      const response = await PromotionService.fetchPromotions({
        company_ids: brand.value.company_id,
        "expand[convert]": true,
        "expand[make_up]": true,
      });

      if (response && response.data) {
        state.promotions = response.data.promotions;
      }
    }

    async function getProductsBestSeller() {
      const response = await ProductService.fetchProducts({
        company_ids: brand.value.company_id,
        sort_by: "sold",
      });

      if (response && response.data) {
        state.productsBestSeller = response.data.products;
      }
    }

    async function getCategoriesBrand() {
      const response = await BrandService.fetchBrand(brand.value.company_id, {
        getCategories: true,
      });

      if (response && response.data) {
        state.categories = response.data;
      }
    }

    return {
      state,
      brand,
    };
  },
};
</script>
