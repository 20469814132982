<template>
  <Teleport to="#modals">
    <Transition duration="500" name="nested">
      <div class="modal" :class="[wrapperClass, show ? 'open' : '']">
        <div :class="modalTypeClass">
          <div class="modal-header d-flex align-items-center justify-content-between">
            <slot name="header"></slot>
            <button class="dosiin_btn-close-modal" @click="closeModal">
              <i class="dsi dsi-close"></i>
            </button>
          </div>
          <div class="modal-body">
            <slot name="body"></slot>
          </div>
          <div class="modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script>
import { ref, watch, toRefs, computed, onMounted } from "vue";
import vClickOutside from "click-outside-vue3";
import { useStore } from "vuex";
export default {
  name: "Modal",
  emits: ["close", "update:modalValue"],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    close: {
      type: Function,
      default: () => undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    wrapperClass: {
      type: String,
      default: "",
    },
    modalTypeClass: {
      type: String,
      default: "modal-full-screen",
    },
    overlay: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const show = ref(props.modalValue);
    const { modelValue } = toRefs(props);
    const store = useStore();

    const modalOpenCount = computed(() => store.getters["modal/countOpen"]);

    onMounted(() => {
      store.dispatch("modal/initModal");
    });

    watch(
      () => props.modelValue,
      (isOpen) => {
        show.value = isOpen;
        if (show.value == true) store.dispatch("modal/increase");
        else store.dispatch("modal/decrease");
      }
    );

    const closeModal = () => {
      show.value = !show.value;
      emit("update:modalValue", show.value);
      emit("close");
    };

    return {
      show,
      closeModal,
    };
  },
};
</script>

<style>
.nested-enter-active,
.nested-leave-active {
  transition: all 0.2s ease-in-out;
}
/* delay leave of parent element */
/* .nested-leave-active {
        transition-delay: 0.1s;
    } */

.nested-enter-from,
.nested-leave-to {
  transform: translateX(30px);
  opacity: 0;
}
</style>
