<template>
  <div>
    <div class="brand-shop-filter-tabs">
      <div class="category-nav-bar">
        <Swiper
          :modules="modules"
          :slidesPerView="'auto'"
          :spaceBetween="16"
          :freeMode="true"
          class="swiper-wrapper nav-bar-swiper-container"
        >
          <SwiperSlide
            class="swiper-slide_underline"
            :class="{ active: currentParams.cid.length === 0 }"
          >
            <a class="dosiin_change-category" @click="changeCategory(null)">Toàn bộ</a>
          </SwiperSlide>
          <SwiperSlide
            class="swiper-slide_underline"
            :class="{ active: currentParams.cid == category.category_id }"
            v-for="(category, index) in state.categories"
            :key="index"
          >
            <a
              class="dosiin_change-category"
              @click="changeCategory(category.category_id)"
              >{{ category.category }}</a
            >
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
    <div class="page-section">
      <ProductLoader :query="currentParams" gridClass="grid-50" :changeGrid="true" />
    </div>
  </div>
</template>

<script>
import ProductLoader from "@/components/product/ProductLoader";
import { inject } from "vue";
import { FreeMode } from "swiper";
import { useRoute } from "vue-router";
export default {
  components: {
    ProductLoader,
  },
  setup() {
    const route = useRoute();
    const state = inject("state");
    const currentParams = inject("params");
    const getCategoriesBrand = inject("getCategoriesBrand");
    if (route.query.cid) {
      currentParams.value.cid = route.query.cid;
    }

    getCategoriesBrand();

    const changeCategory = (cid) => {
      currentParams.value = {
        cid,
        t: "store",
      };
    };

    return {
      state,
      currentParams,
      modules: [FreeMode],
      changeCategory,
    };
  },
};
</script>

<style scoped>
.brand-shop-filter-tabs {
  z-index: 1;
  position: sticky;
  top: 0;
  left: 0;
}
</style>
