// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProductLoader_subText_2ewz3{font-size:10px;font-weight:400;font-style:normal;line-height:150%;letter-spacing:.02em;color:#b3b3b3;text-align:center}", ""]);
// Exports
exports.locals = {
	"subText": "ProductLoader_subText_2ewz3"
};
module.exports = exports;
